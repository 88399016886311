import React, { useEffect } from 'react'
import './contactUs.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaArrowRight } from "react-icons/fa6";
import help_you_out_img3 from "../../Assets/help_you_out_img3.svg";
import register_phone from "../../Assets/register_phone.svg";
import register_whatsup from "../../Assets/register_whatsup.svg";
import register_mail from "../../Assets/register_mail.svg";
import register_location from "../../Assets/register_location.svg";
import contact_us_top_img from "../../Assets/contact_us_top_img.svg";
import { Link , useLocation } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

const ContactUs = () => {

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({top:0,behavior: 'smooth',});
  }, [pathname]);

  const registerForm = (e) => {
    e.preventDefault();
  };

      useEffect(() => {
        AOS.init();
      }, [])


  return (
    <section className='contact_us'>

      <section className='common_top_Section Top_seciton'>
        <h1 data-aos="fade-up" data-aos-delay="5" className='main_title'>Contact us</h1>
        <div data-aos="fade-up" data-aos-delay="5" className='breadcrumbs'><Link to={'/'}><span>Home</span></Link> <FaArrowRight className='breadcrumbs_Arrow' /> <Link to={'/ContactUs'}><span>Contact us</span></Link></div>
        <div data-aos="fade-up" data-aos-delay="5" className='img_div'>
          <img data-aos="fade-up" data-aos-delay="5" src={contact_us_top_img} alt="contactus top img" />
        </div>
      </section>

      <section className='contact_us_help_you_out'>
        <Row className='help_you_out_row' data-aos="fade-up" data-aos-delay="5"        >
          <Col xs={12} sm={6} md={6} lg={6} xxl={6} className='col_main col_main1 '>
            <div className='img_div' data-aos="fade-up" data-aos-delay="5">
              <img src={help_you_out_img3} alt="help you out img" />
            </div>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6} xxl={6} className='col_main col_main2 '>
            <div className='card'>
            <h5 data-aos="fade-up" data-aos-delay="5">Are You looking for Product Based Company</h5>
        <h2 data-aos="fade-up" data-aos-delay="5">Here we are to Help you out</h2>
              <p data-aos="fade-up" data-aos-delay="5">Your success story begins here. Register now to unlock access to premium outsourcing services that will propel your business to new heights of productivity and profitability</p>
              <Link data-aos="fade-up" data-aos-delay="5" to={'/ClientRegister'}><a>Register Here</a></Link>
            </div>
          </Col>
        </Row>
      </section>

      <section className='contact_us_get_in_touch'>
        {/* <div className='content'>
          <h5>Get In Touch</h5>
          <p>We’re here to help you, every step of the way.</p>
        </div> */}
        <Row data-aos="fade-up" data-aos-delay="5">
          {/* <Col xs={12} sm={12} md={6} lg={6} xxl={6} className='col_main col_main1' >
            <form className='register_form'>
              <div className='input_div'>
                <label>Your Name *</label>
                <input type="text" name="Name" id="Name" />
              </div>
              <div className='input_div'>
                <label>Phone *</label>
                <input type="number" name="Phone" id="Phone" />
              </div>
              <div className='input_div'>
                <label>Email *</label>
                <input type="text" name="Email" id="Email" />
              </div>
              <div className='input_div'>
                <label>Subject</label>
                <input type="text" name="Subject" id="Subject" />
              </div>
              <div className='input_div'>
                <label>Message</label>
                <textarea name="Message" id="Message" cols="30" rows="10"></textarea>
              </div>

              <button onClick={registerForm} >Register Here</button>
            </form>
          </Col> */}
          <Col xs={12} sm={12} md={12} lg={12} xxl={12} className='col_main col_main2' >
            <Row className='col_row2' data-aos="fade-up" data-aos-delay="5">
            <Col xs={12} sm={6} md={4} lg={4} xl={4} xxl={4} className='col_row2_main'>
              <div className='card'>
                <div className='img_div'>
                <svg className='svf_images' width="66" height="56" viewBox="0 0 66 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M28.2776 1.60988C29.7326 0.83373 31.3563 0.427734 33.0053 0.427734C34.6543 0.427734 36.278 0.83373 37.733 1.60988L59.8258 13.3956C61.0312 14.0384 62.0732 14.909 62.9062 15.9349L32.9999 31.259L3.10441 15.9376C3.94939 14.8906 4.99712 14.0251 6.18476 13.3929L28.2776 1.60988ZM1.25083 19.5054C0.999048 20.3893 0.867798 21.3134 0.867798 22.259V45.5626C0.867798 48.2266 1.92607 50.7815 3.80981 52.6652C5.69354 54.5489 8.24844 55.6072 10.9124 55.6072H55.0982C57.7622 55.6072 60.3171 54.5489 62.2008 52.6652C64.0845 50.7815 65.1428 48.2266 65.1428 45.5626V22.259C65.1428 21.3134 65.0089 20.384 64.7598 19.5001L33.916 35.2983C33.6325 35.4435 33.3185 35.5193 32.9999 35.5193C32.6814 35.5193 32.3674 35.4435 32.0839 35.2983L1.25083 19.5054Z" fill="white"/>
</svg>

                  {/* <img src={register_mail} alt="register mail" /> */}
                </div>
                <h6 data-aos="fade-up" data-aos-delay="5">Email</h6>        
                  <p className='email_content' data-aos="fade-up" data-aos-delay="5">support@Kippocampus.com</p>
{/* <button>Contact</button> */}
              </div>
            </Col>
            <Col xs={12} sm={6} md={4} lg={4} xl={4} xxl={4} className='col_row2_main'>
              <div className='card'>
                <div className='img_div'>
                <svg className='svf_images' width="58" height="55" viewBox="0 0 58 55" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M39.9273 34.8696L38.4865 36.3041C38.4865 36.3041 35.057 39.7114 25.6995 30.4078C16.342 21.1041 19.7715 17.6968 19.7715 17.6968L20.6772 16.7911C22.916 14.5681 23.1281 10.9961 21.1743 8.38675L17.1843 3.05725C14.765 -0.172747 10.0942 -0.600247 7.32332 2.15475L2.35165 7.09475C0.980485 8.46275 0.0621511 10.2298 0.172984 12.1931C0.457984 17.2186 2.73165 28.0264 15.411 40.6361C28.8598 54.0058 41.479 54.5378 46.6375 54.0564C48.2715 53.9044 49.6902 53.0747 50.8333 51.9347L55.33 47.4634C58.37 44.4456 57.515 39.2681 53.6263 37.1559L47.578 33.8658C45.0257 32.4819 41.9223 32.8873 39.9273 34.8696Z" fill="white"/>
</svg>

                  {/* <img src={register_phone} alt="register phone" /> */}
                </div>
                  <h6 data-aos="fade-up" data-aos-delay="5">Phone</h6>          
                  <p data-aos="fade-up" data-aos-delay="5">9634266709</p>
{/* <button>Call</button> */}
              </div>
            </Col>
            {/* <Col xs={12} sm={6} md={4} lg={4} xl={4} xxl={4} className='col_row2_main'>
              <div className='card'>
                <div className='img_div'>
                <svg className='svf_images' width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M32.5 0.333984C15.0105 0.333984 0.833374 14.5112 0.833374 32.0007C0.833374 37.9857 2.49587 43.5906 5.38704 48.366L2.56237 57.9673C2.40019 58.5186 2.38952 59.1034 2.53148 59.6602C2.67344 60.217 2.96279 60.7253 3.36911 61.1316C3.77543 61.5379 4.28371 61.8273 4.84053 61.9692C5.39734 62.1112 5.98211 62.1005 6.53337 61.9383L16.1347 59.1137C21.0705 62.1001 26.7311 63.6752 32.5 63.6673C49.9895 63.6673 64.1667 49.4902 64.1667 32.0007C64.1667 14.5112 49.9895 0.333984 32.5 0.333984ZM25.337 39.1668C31.7432 45.5698 37.858 46.4153 40.0177 46.4945C43.3015 46.6148 46.4999 44.1068 47.7444 41.1967C47.9015 40.8346 47.9585 40.4371 47.9095 40.0455C47.8605 39.6539 47.7072 39.2826 47.4657 38.9705C45.7304 36.7538 43.3839 35.161 41.0912 33.5777C40.6125 33.2467 40.0243 33.1139 39.4498 33.2072C38.8754 33.3005 38.3594 33.6125 38.01 34.078L36.11 36.9755C36.0102 37.1313 35.8545 37.243 35.6749 37.2877C35.4954 37.3324 35.3055 37.3068 35.1442 37.2162C33.8554 36.4783 31.9775 35.2243 30.6285 33.8753C29.2795 32.5263 28.1015 30.734 27.4397 29.5275C27.3576 29.3742 27.3339 29.1964 27.3729 29.0269C27.4119 28.8575 27.511 28.708 27.6519 28.606L30.5779 26.4337C30.9954 26.0706 31.2649 25.5666 31.335 25.0177C31.4051 24.4688 31.2708 23.9133 30.9579 23.457C29.5392 21.3797 27.8862 18.7387 25.489 16.9875C25.1797 16.7633 24.8173 16.6235 24.4375 16.5819C24.0577 16.5402 23.6736 16.5982 23.323 16.75C20.4097 17.9977 17.889 21.196 18.0094 24.4862C18.0885 26.6458 18.934 32.7607 25.337 39.1668Z" fill="white"/>
</svg>

                </div>
                  <h6>Whatsapp</h6>          
                  <p>082-123-234-345</p>
<button></button>
                  </div>
            </Col> */}
            <Col xs={12} sm={6} md={4} lg={4} xl={4} xxl={4} className='col_row2_main'>
              <div className='card' data-aos="fade-up" data-aos-delay="5">
                <div className='img_div'>
                <svg className='svf_images' width="76" height="75" viewBox="0 0 76 75" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M27.8438 31.25C27.8438 28.5564 28.9138 25.9731 30.8184 24.0684C32.7231 22.1638 35.3064 21.0938 38 21.0938C40.6936 21.0938 43.2769 22.1638 45.1816 24.0684C47.0862 25.9731 48.1562 28.5564 48.1562 31.25C48.1562 33.9436 47.0862 36.5269 45.1816 38.4316C43.2769 40.3362 40.6936 41.4062 38 41.4062C35.3064 41.4062 32.7231 40.3362 30.8184 38.4316C28.9138 36.5269 27.8438 33.9436 27.8438 31.25Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.2938 27.7406C12.8122 21.4581 15.6739 15.6001 20.3109 11.3296C24.9479 7.059 31.0211 4.68811 37.3251 4.6875H38.6751C44.979 4.68811 51.0522 7.059 55.6892 11.3296C60.3262 15.6001 63.1879 21.4581 63.7063 27.7406C64.2854 34.7547 62.1199 41.7197 57.6657 47.1688L42.6876 65.4875C42.1199 66.1823 41.4048 66.7423 40.5941 67.1268C39.7834 67.5113 38.8973 67.7108 38.0001 67.7108C37.1028 67.7108 36.2167 67.5113 35.406 67.1268C34.5953 66.7423 33.8803 66.1823 33.3126 65.4875L18.3376 47.1688C13.8823 41.7202 11.7155 34.7551 12.2938 27.7406ZM38.0001 16.4062C34.0633 16.4062 30.2877 17.9701 27.5039 20.7539C24.7202 23.5376 23.1563 27.3132 23.1563 31.25C23.1563 35.1868 24.7202 38.9624 27.5039 41.7461C30.2877 44.5299 34.0633 46.0938 38.0001 46.0938C41.9369 46.0938 45.7124 44.5299 48.4962 41.7461C51.2799 38.9624 52.8438 35.1868 52.8438 31.25C52.8438 27.3132 51.2799 23.5376 48.4962 20.7539C45.7124 17.9701 41.9369 16.4062 38.0001 16.4062Z" fill="white"/>
</svg>

                  {/* <img src={register_location} alt="register location" /> */}
                </div>
                  <h6 data-aos="fade-up" data-aos-delay="5">Location</h6>          
                  <p data-aos="fade-up" data-aos-delay="5">33, Hakim Ajmal Khan Rd, Chinna Chokikulam, Madurai, Tamil Nadu 625002</p>
{/* <button>Locate</button> */}
              </div>
            </Col>
            </Row>
          </Col>
        </Row>
      </section>

    </section>
  )
}

export default ContactUs