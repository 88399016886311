import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { AuthProvider } from "./context/AuthContext";
import Home from "./components/screens/Home/Home";
import About from "./components/screens/About_us/About";
import Header from "./components/common/Header/Header";
import Footer from "./components/common/Footer/Footer";
import ContactUs from "./components/screens/Contact_us/ContactUs";
import Service from "./components/screens/service/Service";
import Register from "./components/screens/client register/Register";
import Careers from "./components/screens/careers/Careers";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    // <AuthProvider>
    <BrowserRouter>
     <ToastContainer />
      <Routes>
      {/* <Route exact path="/" element={<Home />} /> */}
        <Route exact path="/" element={
          <div className="d-flex main_body">
              <Header />
              <Home />
              <Footer />
          </div>
        }></Route>
        <Route exact path="/AboutUs" element={
          <div className="d-flex main_body">
              <Header />
              <About />
              <Footer />
          </div>
        }></Route>
        <Route exact path="/ContactUs" element={
          <div className="d-flex main_body">
              <Header />
              <ContactUs />
              <Footer />
          </div>
        }></Route>
        <Route exact path="/Service" element={
          <div className="d-flex main_body">
              <Header />
              <Service />
              <Footer />
          </div>
        }></Route>
        <Route exact path="/ClientRegister" element={
          <div className="d-flex main_body">
              <Header />
              <Register />
              <Footer />
          </div>
        }></Route>
        <Route exact path="/Careers" element={
          <div className="d-flex main_body">
              <Header />
              <Careers />
              <Footer />
          </div>
        }></Route>
      </Routes>
    </BrowserRouter>
  
  
  // </AuthProvider>
  );
}

export default App;
