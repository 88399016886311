import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import logo from "../../Assets/logo.svg";
import home_icon from "../../Assets/home_icon.svg";
import { FaPhoneFlip } from "react-icons/fa6";
import { IoCall } from "react-icons/io5";
import './header.css'
import { Link , useLocation } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Header() {

  const location = useLocation(); // once ready it returns the 'window.location' object
  const [url, setUrl] = useState(null);
  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({top:0,behavior: 'smooth',});
  }, [pathname]);

      useEffect(() => {
        AOS.init();
      }, [])

  return (
    <section className='header' data-aos="fade-up" data-aos-delay="5">
         <>
    {['md'].map((expand) => (
      <Navbar key={expand} expand={expand} className="header">
        <Container fluid>
          <Navbar.Brand href="#" className='nav_brand' data-aos="fade-up" data-aos-delay="5">
            <Link to={"/"}><img src={logo} alt="logo" /></Link>
            <span className='logo_name'>
            (A Unit  of Kevell Global Solutions)
            </span>
          </Navbar.Brand>
          <Navbar.Toggle onClick={handleShow} />
          <Navbar.Offcanvas
            show={show} onHide={handleClose}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-center flex-grow-1 pe-3 main_menu" >
                <Nav.Link onClick={handleClose} ><Link to="/" className={(url === "/" ? "active" : "")} >
                  <svg width="24" className='home_icon' height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_5_32)">
                      <path d="M1.71436 12.5L12.0001 2.21429L22.2858 12.5" stroke="#EAB514" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M4 10.2143V18.2143C4 18.8205 4.24082 19.4019 4.66947 19.8305C5.09812 20.2592 5.67951 20.5 6.28571 20.5H17.7143C18.3205 20.5 18.9019 20.2592 19.3305 19.8305C19.7592 19.4019 20 18.8205 20 18.2143V10.2143" stroke="#EAB514" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_5_32">
                        <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
                      </clipPath>
                    </defs>
                  </svg>
                </Link></Nav.Link>
                <Nav.Link onClick={handleClose}><Link to="/Service" className={(url === "/Service" ?"active" : "")} >Services</Link></Nav.Link>
                <Nav.Link onClick={handleClose}><Link to="/Careers" className={(url === "/Careers" ?"active" : "")} >Career</Link></Nav.Link>
                <Nav.Link onClick={handleClose}><Link to="/ContactUs" className={(url === "/ContactUs" ?"active" : "")} >Contact Us</Link></Nav.Link>
                <Nav.Link onClick={handleClose}><Link to="/AboutUs" className={(url === "/AboutUs" ?"active" : "")} >About Us</Link></Nav.Link>
              </Nav>
              <Form className="d-flex" data-aos="fade-up" data-aos-delay="5">
                <Link to="/ContactUs" onClick={handleClose}><Button className='contact_us_header'><IoCall /> Call Free</Button></Link>
              </Form>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    ))}
  </>
    </section>
  )
}

export default Header