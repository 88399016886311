import React, { useEffect } from 'react'
import "./footer.css"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IoLocationOutline } from "react-icons/io5";
import footer_logo from "../../Assets/footer_logo.svg";
import footer_contact_icon from "../../Assets/footer_contact_icon.svg";
import footer_email_icon from "../../Assets/footer_email_icon.svg";
import footer_location_icon from "../../Assets/footer_location_icon.svg";
import { Link, useLocation } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { RiFacebookFill } from "react-icons/ri";
import { ImTwitter } from "react-icons/im";
import { AiFillInstagram } from "react-icons/ai";
import { IoLogoWhatsapp } from "react-icons/io";

function Footer() {

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth', });
  }, [pathname]);

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <section className='footer'>
      {/* <Container fluid> */}

      <Row className='footer_content_top' data-aos="fade-up" data-aos-delay="5">
        <Col xs={12} sm={12} md={3} lg={3} xl={3} xxl={3} >
          <Link to={"/"}>
            <div className='img_div' data-aos="fade-up" data-aos-delay="5">
              <img data-aos="fade-up" data-aos-delay="5" src={footer_logo} alt="footer logo img" />
            </div>
          </Link>
        </Col>
        <Col xs={12} sm={12} md={9} lg={9} xl={9} xxl={9} >
          <p data-aos="fade-up" data-aos-delay="5" className='company_description'>We’re here to make your experience as seamless as possible </p>
        </Col>
      </Row>

      <Row className='footer_row' data-aos="fade-up" data-aos-delay="5">
        <Col xs={12} sm={12} md={3} lg={3} xl={3} xxl={3} >
          <div className='usefull_links'>
            <h5 data-aos="fade-up" data-aos-delay="5">Useful Links</h5>
            <ul>
              <li><span data-aos="fade-up" data-aos-delay="5"><Link to="/AboutUs">About Company</Link></span></li>
              <li><span data-aos="fade-up" data-aos-delay="5"><Link to="/Careers">Career</Link></span></li>
              <li><span data-aos="fade-up" data-aos-delay="5"><Link to="/Service">Services</Link></span></li>
              <li><span data-aos="fade-up" data-aos-delay="5"><Link to="/ContactUs">Contact</Link></span></li>
            </ul>
          </div>
        </Col>
        <Col xs={12} sm={12} md={5} lg={5} xl={5} xxl={5} >
          <div className='address_links' data-aos="fade-up" data-aos-delay="5">
            <div className='img_div'>
              <img src={footer_location_icon} alt="footer location icon img" />
            </div>
            <h5 data-aos="fade-up" data-aos-delay="5">Address</h5>
            <p data-aos="fade-up" data-aos-delay="5">33, Hakim Ajmal Khan Rd, Chinna Chokikulam, Madurai, Tamil Nadu 625002</p>

          </div>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} >
          <ul className='contact_links'>
            <li>
              <h5 data-aos="fade-up" data-aos-delay="5">Contact</h5>
            </li>
            <li>
              <div className='img_div'>
                <img data-aos="fade-up" data-aos-delay="5" src={footer_contact_icon} alt="footer contact icon img" />
              </div>
              <p data-aos="fade-up" data-aos-delay="5">+91 9789191180</p>
              <p data-aos="fade-up" data-aos-delay="5">+91 7010212129</p>
            </li>
            <li className='email_footer'>
              <div className='img_div'>
                <img src={footer_email_icon} alt="footer email icon img" />
              </div>
              <p data-aos="fade-up" data-aos-delay="5">sales@Kippocamp.com</p>
              <p data-aos="fade-up" data-aos-delay="5">service@Kippocamp.com</p>
            </li>
          </ul>
        </Col>
      </Row>
      <Row className='follow_us_row'>
        <section className='follow_us' data-aos="fade-up" data-aos-delay="5">
          <h2>FOLLOW US</h2>
          <ul>
            <li><a href=""><RiFacebookFill /></a></li>
            <li><a href=""><ImTwitter /></a></li>
            <li><a href=""><AiFillInstagram /></a></li>
            <li><a href=""><IoLogoWhatsapp /></a></li>
          </ul>
        </section>
      </Row>
      {/* </Container> */}
    </section>
  )
}

export default Footer