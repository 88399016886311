import React,{useState,useEffect} from 'react'
import './home.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import why_choose_us_img from "../../Assets/why_choose_us_img_new.svg";
import contact_center_service from "../../Assets/contact_center_service.svg";
import smart_solutoin_img from "../../Assets/smart_solutoin_img.png";
import top_section_card_img from "../../Assets/top_section_card_img.svg";
import our_mission2 from "../../Assets/our_mission2.png";
import our_vision2 from "../../Assets/our_vision2.png";
import whatwe_do_image2 from "../../Assets/whatwe_do_image2.svg";
import whatwe_do_image3 from "../../Assets/whatwe_do_image3.svg";
import expertice_image2 from "../../Assets/expertice_image2.svg";
import expertice_image3 from "../../Assets/expertice_image3.svg";
import expertice_image4 from "../../Assets/expertice_image4.svg";
import video from "../../Assets/video2.mp4";
import { RiFacebookFill } from "react-icons/ri";
import { ImTwitter } from "react-icons/im";
import { AiFillInstagram } from "react-icons/ai";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaPlay } from "react-icons/fa";
import { FaPause } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { Link , useLocation } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { TypeAnimation } from 'react-type-animation';
import { Parallax } from 'react-next-parallax';
import Carousell from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Accordion from 'react-bootstrap/Accordion';
import testimonials_profile from "../../Assets/actimage.png";
import testimonials_profile1 from "../../Assets/images.png";
import testimonials_profile2 from "../../Assets/Tata.png";


function Home() {

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({top:0,behavior: 'smooth',});
  }, [pathname]);

  const [play, setplay] = useState(true)
  var myVideo = document.getElementById("singleVideo");

  useEffect(() => {
    myVideo?.addEventListener('ended', function() {
      setplay(true)
    });
    myVideo?.addEventListener('pause', function() {
      setplay(true)
    });
    myVideo?.addEventListener('play', function() {
      setplay(false)
    });
  },)  

  useEffect(() => {
    AOS.init();
  }, [])

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const popupClose = () => {

    var myVideo = document.getElementById("singleVideo");

    if(play){
      myVideo.play();
    }
    else if(!play){
      myVideo.pause();
    }
    setplay(!play)
  }

  return (
    <Container fluid className="my-container">

      <section className='smart_Solution'>
        <Row className='smart_Solution_row' data-aos="fade-up" data-aos-delay="5">
          <Col xs={12} sm={12} md={12} lg={12} xxl={12} className='col_main col_main_1'>
          {/* <TypeAnimation className='typeAnimationsh6'
  style={{ whiteSpace: 'pre-line', height: '195px', display: 'block' }}
  sequence={[
    `SMART SOLUTIONS`,
    1000,
  ]}
  repeat={0}
  cursor={false}
/> */}
          {/* <TypeAnimation className='typeAnimationsh2'
  style={{ whiteSpace: 'pre-line', height: '195px', display: 'block' }}
  sequence={[
    `Empowering connection, Elevating Experience & Your Voice process Partner of Choice\ Improve customer experience by listening and acting on their feedback`,
    1000,
  ]}
  repeat={0}
  cursor={false}
/> */}
            <h6 data-aos="fade-up" data-aos-delay="5">SMART SOLUTIONS</h6>
            <h2 data-aos="fade-up" data-aos-delay="5">EMPOWERING CONNECTIONS, ELEVATING EXPERIENCES & YOUR VOICE PARTNER PROCESS OF CHOICE</h2>
            <p data-aos="fade-up" data-aos-delay="5">‘Enhancing customer experience through attentive listening and proactive response to their feedback’</p>
            <p data-aos="fade-up" data-aos-delay="5">At Kippocampus, we specialize in transforming your communication needs into exceptional experiences. We are dedicated to empowering your business with seamless connections and elevated customer interactions. As your voice process partner of choice, we deliver unparalleled service quality, ensuring your brand’s voice is heard loud and clear.</p>
            <Link to="/ContactUs"><button><IoCall /> Call Free</button></Link>
          </Col>
          {/* <Col xs={12} sm={12} md={1} lg={6} xxl={6} className='col_main col_main_2'>
            <img data-aos="fade-up" data-aos-delay="5" src={smart_solutoin_img} alt="smart solutoin img" />
          </Col> */}
        </Row>

        {/* data-aos="fade-up" data-aos-delay="5" */}
        <Row className='smart_Solution_row2' >
        <Col xs={12} sm={12} md={12} lg={12} xxl={12} className='top_card_main'>
            <Row className='top_center_card_row'>
              <Col xs={12} sm={6} md={4} lg={4} xxl={4}>
                <div className='card card1'>
                {/* <img src={top_section_card_img} alt="" /> */}
                </div>
              </Col>
              <Col xs={12} sm={6} md={4} lg={4} xxl={4} >
                  <div className='card card2'>
                <h5><span>Our</span><span className='our_vision_span'>Vision</span></h5>
                <p>Our vision is to deliver premier Business Process Outsourcing services by integrating cutting-edge technology, a team of talented and dedicated professionals, and an unwavering commitment to quality.</p>
                </div>
              </Col>
              <Col xs={12} sm={6} md={4} lg={4} xxl={4}>
                <div className='card card3'>
                {/* <h5>Our Mission</h5> */}
                <h5><span>Our</span><span className='our_mission_span' >Mission</span></h5>
                <p>Our mission is to offer top-tier BPO services to our clients at competitive prices. We strive to simplify the outsourcing process, ensure seamless communication, and drive continuous improvement in voice-based services.</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>


      <section className='What_we_do' >
        <Row className='What_we_do_row' data-aos="fade-up" data-aos-delay="5">
          <Col xs={12} sm={12} md={6} lg={6} xxl={7} className='col_main col_main_1' data-aos="zoom-in-up">
          {/* <h2 data-aos="fade-up">WHAT WE DO</h2> */}
        <h2 data-aos="fade-up"> WHAT WE DO <br></br> Optimizing Your Customer Support Experience</h2>
            <p data-aos="fade-up" data-aos-delay="5">At Kippocampus, we specialize in delivering comprehensive solutions tailored to meet the diverse needs of businesses in the BPO voice process industry. By leveraging cutting-edge technology and a team of skilled professionals, we offer an extensive range of services, including customer support, sales, telemarketing, and more.</p>
            <p data-aos="fade-up" data-aos-delay="5">Our key offerings include:</p>
            <ul>
              <li><h6 data-aos="fade-up" data-aos-delay="5">Multilingual Support:</h6> Providing seamless customer interactions in multiple languages to cater to global clients.</li>
              <li><h6 data-aos="fade-up" data-aos-delay="5">Outsourced Data Analytics:</h6> Delivering insightful data analytics services to help businesses make informed decisions and optimize performance.</li>
              <li><h6 data-aos="fade-up" data-aos-delay="5">Contact Center Outsourcing:</h6> Offering reliable and efficient contact center outsourcing solutions to enhance customer and client satisfaction and operational efficiency.</li>
            </ul>
            <p>Partner with Kippocampus to experience exceptional service quality, innovative solutions, and a dedicated team committed to elevating your business operations!</p>
            {/* <button className='Know_more'>Know more</button> */}
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xxl={5} className='col_main col_main_2' data-aos="zoom-in-up">
            <div className="what_we_do_grid">
              <div className="what_we_do_grid_item1">
              <button type="button" onClick={()=>{
              popupClose()
            }} >
                    { play ? <FaPlay />: <FaPause />}
                    {/* <FaPlay /> */}
                </button>
            <div className='video_div'>
              <vg-player>
                      <video id="singleVideo" width="100%" height="100%" controls disablepictureinpicture="false" controlslist="nodownload noplaybackrate" >
                          <source src={video} type="video/mp4"/>
                      </video>
                  </vg-player>
            </div>
              </div>
              <div className="what_we_do_grid_item2">
                <div className='img_div'>
                  <img src={whatwe_do_image2} alt="whatwe_do_image2" />
                </div>
              </div>
              <div className="what_we_do_grid_item3">
                <div className='item3_main'>
                <div className="img_div">
                  <img src={whatwe_do_image3} alt="whatwe_do_image3" />
                </div>
                <span>Kippocamp.com</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </section>

      <section className='expertice' >
        <Row className='expertice_row' data-aos="fade-up" data-aos-delay="5">
          <Col xs={12} sm={12} md={6} lg={6} xxl={5} className='col_main col_main_1' data-aos="zoom-in-up">
          {/* <h2 data-aos="fade-up">EXPERTISE</h2> */}
          <div className="expertice_grid">
              <div className="expertice_grid_item1">
              <button type="button" onClick={()=>{
              popupClose()
            }} >
                    { play ? <FaPlay />: <FaPause />}
                    {/* <FaPlay /> */}
                </button>
            <div className='video_div'>
              <vg-player>
                      <video id="singleVideo" width="100%" height="100%" controls disablepictureinpicture="false" controlslist="nodownload noplaybackrate" >
                          <source src={video} type="video/mp4"/>
                      </video>
                  </vg-player>
            </div>
              </div>
              <div className="expertice_grid_item2">
                <div className='img_div'>
                  <img src={expertice_image2} alt="whatwe_do_image2" />
                </div>
              </div>
              <div className="expertice_grid_item3">
                <div className='item3_main'>
                <div className="img_div">
                  <img src={expertice_image3} alt="whatwe_do_image3" />
                </div>
                <div className="img_div">
                  <img src={expertice_image4} alt="whatwe_do_image3" />
                </div>
                </div>
              </div>
            </div>
            {/* <button className='Know_more'>Know more</button> */}
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xxl={7} className='col_main col_main_2' data-aos="zoom-in-up">
            <h2 data-aos="fade-up">EXPERTISE <br></br>WHAT BPO MEANS TO US</h2>
            <p style={{width: '100%',}}>Our commitment is to provide high-quality customer care and true brand ambassadorship. By applying our professional culture with specialized training, our Team member become an essential extension to your team. We are dedicated to ensuring complete satisfaction with honesty and transparency.</p>
            <ul>
              <li>Accuracy</li>
              <li>Transparency</li>
              <li>Low Total Cost to Serve</li>
              <li>Scalable</li>
              <li>Professional</li>
            </ul>
          </Col>
        </Row>
      </section>

      {/* <section className='What_we_do' style={{paddingTop:'70px'}}>
        <h2 data-aos="fade-up">EXPERTISE</h2>
        <h2 data-aos="fade-up">WHAT BPO MEANS TO US</h2>
        <Row className='What_we_do_row'>
          <Col xs={12} sm={12} md={12} lg={12} xxl={12} className='col_main col_main_1' data-aos="zoom-in-up">
          <p style={{width: '100%',}}>Our commitment is to provide high-quality customer care and true brand ambassadorship. By applying our professional culture with specialized training, our Team member become an essential extension to your team. We are dedicated to ensuring complete satisfaction with honesty and transparency.</p>
            <ul>
              <li>Accuracy</li>
              <li>Transparency</li>
              <li>Low Total Cost to Serve</li>
              <li>Scalable</li>
              <li>Professional</li>
            </ul>
          </Col>
        </Row>
      </section> */}

      <section className='why_choose_us'>
        <Row className='why_choose_us_row' data-aos="fade-up" data-aos-delay="5">
          <Col xs={12} sm={12} md={8} lg={6} xxl={6} className='col_main col_main_1' data-aos="zoom-in-up">
            <div className='img_div'>
              <img src={why_choose_us_img} alt="why choose us img" />
              <span className='experience_div'>
                <span>20</span>
                <span>Years Of Experience</span>
              </span>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xxl={6} className='col_main col_main_2' data-aos="zoom-in-up">
            <h3>WHY CHOOSE US</h3>
            <h5>We work with you to find the solution perfect for your needs’</h5>
            {/* <p>It includes communication skills, product or service training, call handling techniques, customer service best practices, and company policies and procedures.</p> */}
            
            <Accordion defaultActiveKey="0" className='accordtion_why_choose_us'>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Tailored Solutions</Accordion.Header>
        <Accordion.Body>
        At Kippocampus, we begin by thoroughly understanding your unique business requirements and objectives. Our dedicated team collaborates closely with you to craft customized voice process solutions that precisely align with your specific needs and goals.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Quality Assurance</Accordion.Header>
        <Accordion.Body>
        We uphold stringent quality assurance measures to maintain consistently high standards of service delivery. Our team conducts real-time monitoring of interactions, performs regular performance evaluations, and implements robust feedback mechanisms to continuously enhance service quality.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Data Security & Compliance</Accordion.Header>
        <Accordion.Body>
        We prioritize the security and confidentiality of your sensitive information. Our systems are equipped with robust security protocols to safeguard data, ensuring compliance with industry regulations such as GDPR and PCI-DSS.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Trusted Partnership</Accordion.Header>
        <Accordion.Body>
        We are more than just a service provider; we are your dedicated partner, working collaboratively to achieve your business goals and elevate your brand’s presence.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
            
            {/* <ul>
              <li>
                <h6>Tailored Solutions</h6>
                <p> We begin by understanding your unique business requirements and objectives. Our team works closely with you to customize voice process solutions that align with your specific needs & goals</p>
              </li>
              <li>
                <h6>Quality Assurance</h6>
                <p>We maintain stringent quality assurance measure to ensure consistently high standards of service delivery. Our team monitors interactions in real time, conducts regular performance evaluations and implements feedback mechanisms to continuously improve service quality.</p>
              </li>
              <li>
                <h6>Data Security & Compliance</h6>
                <p>We prioritise the security & confidential of your sensitive information. Our systems are equipped with robust security protocols to safeguard data and ensure compliance with industry regulation such as GDPR and PCI-DSS.</p>
              </li>
            </ul> */}

          </Col>
        </Row>
      </section>

      <section className='bpo_benfits' style={{paddingTop:'150px'}}>
        <Row className='bpo_benfits_row' data-aos="fade-up" data-aos-delay="5">
          <Col xs={12} sm={12} md={6} lg={6} xxl={6} className='col_main col_main_1 bnenefits_for_bpo_col' data-aos="zoom-in-up">
        <h2 data-aos="fade-up">HOW BPO BENEFITS YOUR BUSINESS</h2>
            {/* <p>At Kippocampus, We Specialise in providing comprehensive solutions to meet the diverse needs of business in the BPO voice process. Leveraging cutting edge technology and team of skilled professionals, we offer a range of services including Customer support, sales, telemarketing and more.</p> */}
            <ul>
              <li>We are an extension of your office, connecting customers with your brand in more meaningful ways. BPO ensures reliable, timely, and personal communication that results in better customer experiences with our expert English speaking customer service teams</li>
              <li>Our outstanding team members enable BPO Centers to effectively handle many of your tedious processes, which gives you freedom to focus on growth</li>
            </ul>
            {/* <button className='Know_more'>Know more</button> */}
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xxl={6} className='col_main col_main_2 difference_for_bpo_col' data-aos="zoom-in-up">
          <h2 data-aos="fade-up">THE BPO DIFFERENCE</h2>
          <ul>
              <li>Multilingual staff with a global understanding of how to properly communicate with the differing needs of each customer.</li>
              <li>Talented managers trained and skilled in all levels of back office support to keep your company thriving.</li>
            </ul>
          </Col>
        </Row>
      </section>

      <section className='What_we_do' style={{paddingTop:'50px'}}>
        <Row className='What_we_do_row' data-aos="fade-up" data-aos-delay="5">
          <Col xs={12} sm={12} md={12} lg={12} xxl={12} className='col_main col_main_1' data-aos="zoom-in-up">
        <p><b style={{color:'rgba(234, 181, 20, 1)',fontWeight:'500'}}>Kippocampus is one of the best business process outsourcing companies when it comes to providing clients with outstanding customer support.</b> Our exceptional agents and premier outsourcing solutions provide the best proactive support procedures to resolve customer issues before they arise. The biggest business process outsourcing advantage over other BPOs is that our team members are native English speakers , they have many cultural similarities to your clients.  As your company builds and maintains a highly-effective customer support operation, Kippocampus becomes a trusted partner and seamless extension of your team.</p>
            <p><b style={{color:'rgba(234, 181, 20, 1)',fontWeight:'500'}}>Kippocampus hires top-quality agents and provides a work environment of professionalism and fun.</b> Our company culture and happy agents offer your customers great experiences, which increase retention, sparks growth, and lowers the overall cost to serve.</p>
            {/* <button className='Know_more'>Know more</button> */}
          </Col>
        </Row>
      </section>

      <section className='What_we_do' style={{paddingTop:'70px',marginBottom:'150px'}}>
      <h2 data-aos="fade-up" data-aos-delay="5">Testimonials</h2>
        <Row className='What_we_do_row' data-aos="fade-right"  data-aos-offset="300"
     data-aos-easing="ease-in-sine">
        <Col xs={12} sm={12} md={6} lg={6} xxl={6} className='col_main col_main_1 testimonials_col1' style={{display:'flex'}} data-aos="zoom-in-up">
        <h2 data-aos="fade-up" data-aos-delay="5" >WHAT OUR CLIENTS SAY ABOUT US</h2>
        {/* style={{width: '75%',alignSelf:'center',textAlign:'right'}} */}
        </Col>
          <Col xs={12} sm={12} md={6} lg={6} xxl={6} className='col_main col_main_1 testimonials_col2' data-aos="zoom-in-up">
          <Carousell data-aos="fade-left"  data-aos-offset="300"
     data-aos-easing="ease-in-sine"
          className='testimonials_carousel'
  // swipeable={false}
  draggable={false}
  showDots={false}
  responsive={responsive}
  // ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={true}
  arrows={true}
  // autoPlaySpeed={1000}
  // keyBoardControl={true}
  // customTransition="all .5"
  // transitionDuration={500}
  // containerClass="carousel-container"
  // removeArrowOnDeviceType={["tablet", "mobile","laptop"]}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
>
  <div><p data-aos="fade-up" data-aos-delay="5">The agents were very professional and courteous throughout the call, making me feel valued as a customer.</p></div>
  <div><p data-aos="fade-up" data-aos-delay="5">The agents spoke clearly and were easy to understand, which is crucial for effective communication.</p></div>
  <div><p data-aos="fade-up" data-aos-delay="5">The service felt personalized; the agent referred to my previous interactions and tailored their responses accordingly.</p></div>
  <div><p data-aos="fade-up" data-aos-delay="5">The representatives demonstrated a good understanding of the products/services and provided accurate information.</p></div>
</Carousell>;
          </Col>
        </Row>
      </section>

      <scetion className='contact_technology'>
        <div className='contact_technology_main' data-aos="zoom-in-up">
          <img src={contact_center_service} alt="contact center service img" />
          <div className='sub_box_div' data-aos="fade-right" data-aos-delay="5">
            <h6>Learn About Our Solutions, Contact Center & Technology</h6>
            <p>It is supported by many technologies designed to enhance the customer experience, improve the operating and management efficiency, or lower the overall costs of running a contact center.</p>
            <button><Link to={'/Service'}>See Services</Link></button>
          </div>
        </div>
      </scetion>

      
      <section className='accordion_section' data-aos="fade-up" data-aos-delay="5">
      <Accordion defaultActiveKey="0" className='bottom_accordion_main'>
      <Accordion.Item eventKey="0">
        <Accordion.Header>What is BPO service in India, and how does it work?</Accordion.Header>
        <Accordion.Body>
        Business process outsourcing or BPO service in India refers to tasking out certain functional tasks to a third-party service provider. Internal business functions such as billing and purchasing are outsourced, while front-of-house functions including customer support, marketing, and product customization are outsourced. All you need to do is to understand what services you wish to outsource and services you want to keep in-house.
        BPO services in India are growing at a fast pace and expanding to assist with services like conventional back and front office functions to services like digital marketing services and social media marketing. Moreover, BPO services can help you save big on cost, offer flexibility, agility, and access to tools and processes.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>What are BPO advantages and disadvantages?</Accordion.Header>
        <Accordion.Body>
        Some of the major advantages of BPO services include lower cost, higher efficiency, flexibility, and agility it offers. But the most important benefit of hiring BPO services is that it allows you to focus on core business functions. Yes, by outsourcing a few processes like HR, accounting, payroll, and others, you can focus on the important aspects of your business and grow while generating bigger revenues. Furthermore, companies benefit from BPO's ability to offer prompt and accurate reporting, leading to increased productivity, as well as help in reassigning resources when needed.
        However, other than benefits, it comes with drawbacks like security issues, hidden costs, and communication problems.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>How many BPO is in India?</Accordion.Header>
        <Accordion.Body>
        India is home to a large number of BPOs. There are more than 374 BPO companies in India. If you are looking for a reliable and trusted BPO company, Kippocampus is the best choice for you.
        If you want proven results and the benefits of outsourcing, Kippocampus is the best service provider for you. Kippocampus offers all the assistance you need to outsource non-core and administrative functions.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>What is the role of BPO?</Accordion.Header>
        <Accordion.Body>
        BPOs provide companies with flexibility and cost-saving third-party services by implementing dedicated providers, which can handle specialized tasks, such as payroll, more efficiently and pass those savings to their clients. You can outsource any business function through a BPO.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
      </section>

      {/* <section className='follow_us' data-aos="fade-up" data-aos-delay="5">
        <h2>FOLLOW US</h2>
        <ul>
          <li><a href=""><RiFacebookFill /></a></li>
          <li><a href=""><ImTwitter /></a></li>
          <li><a href=""><AiFillInstagram /></a></li>
          <li><a href=""><IoLogoWhatsapp /></a></li>
        </ul>
      </section> */}
      <section className='our_clients'>
        <h2 data-aos="fade-up" data-aos-delay="5">OUR CLIENT'S</h2>
        <Row className='our_clients_row' data-aos="fade-up" data-aos-delay="5">
         
          <Col xs={12} sm={12} md={6} lg={4} xxl={4} className="col_main col_main3">
            <div className="card">
              <div className="img_div">
                <img src={testimonials_profile} alt="testimonials profile img" />
              </div>
              <h5>ACT</h5>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} xxl={4} className="col_main col_main4" data-aos="fade-up" data-aos-delay="5"> 
            <div className="card">
              <div className="img_div">
               <img src={testimonials_profile1} alt="testimonials profile img" />
              </div>
              <h5>India Mart</h5>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} xxl={4} className="col_main col_main5" data-aos="fade-up" data-aos-delay="5">
            <div className="card">
              <div className="img_div">
                 <img src={testimonials_profile2} alt="testimonials profile img" />
              </div>
       
              <h5>TATA Telesales Services</h5>
    
            </div>
          </Col>
         
        </Row>
      </section>
    </Container>
  )
}

export default Home